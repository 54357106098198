import React from "react";
import { Helmet } from "react-helmet";

import { PageTitleProps } from "../../../utils/types";

export const PageTitle: React.FC<PageTitleProps> = ({ subtitle }) => {
  const line = "|"
  const title = `${subtitle} ${line} Oke Software`
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}
