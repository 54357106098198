import styled from "styled-components";

import { device } from "../../utils/breakpoints";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.computer}, ${device.tablet}, ${device.tabletLandscape} {
    min-height: 100vh;
  };
`;